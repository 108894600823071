<template>
  <div
    :class="boxClass"
    class="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
    role="status"
  >
    <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
    >
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { getNormalizeValue } from '@/utils/config';

const props = defineProps({
  borderWidth: {
    type: String,
    default: 'border-2'
  },
  boxDimensions: {
    type: String,
    default: 'h-4 w-4'
  }
});
const boxClass = computed(() => {
  const bClass = `${props.borderWidth} ${props.boxDimensions}`;
  return getNormalizeValue(bClass);
});
</script>
